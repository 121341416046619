<template>
    <div class="wrapper" @mousemove="onHover" @mouseenter="onEnter" @mouseleave="onLeave">
        <div
            ref="img"
            class="img"
            :style="{
                width: width + 'px',
                height: height + 'px',
            }"
        >
            <img v-if="loaded" :src="require(`@/assets/images/includes/${imgName}.png`)" />
        </div>
        <slot></slot>
    </div>
</template>

<script>
import gsap from 'gsap'

export default {
    name: 'HoverAnimation',
    props: {
        imgName: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            required: true,
        },
        height: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loaded: false,
        }
    },
    methods: {
        onLeave() {
            if (window.matchMedia('(min-width: 767px)').matches) {
                const img = this.$refs.img

                gsap.to(img, {
                    opacity: 0,
                    duration: 1,
                })
            }
        },
        onEnter(e) {
            if (window.matchMedia('(min-width: 767px)').matches) {
                this.loaded = true

                const { layerX, layerY } = e
                const img = this.$refs.img

                gsap.killTweensOf(img)
                gsap.set(img, {
                    x: layerX,
                    y: layerY,
                })
                gsap.to(img, {
                    opacity: 1,
                    duration: 1,
                })
                gsap.from(img, {
                    scale: 1.2,
                    duration: 1,
                })
            }
        },
        onHover(e) {
            if (window.matchMedia('(min-width: 767px)').matches) {
                if (this.blockAnimation) {
                    return
                }

                const { layerX, layerY } = e
                const img = this.$refs.img

                gsap.to(img, {
                    x: layerX,
                    y: layerY,
                    ease: 'elastic',
                    duration: 8,
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.wrapper {
    position: relative;
}
.img {
    @media (max-width: 767px) {
        display: none;
    }
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
    transform: translate(-50%, -50%);
    opacity: 0;
}
</style>
